@charset 'utf-8';
@import 'react-day-picker/lib/style.css';

.DayPicker-wrapper {
	outline: none;
	max-width: 450px;

	.DayPicker-NavButton--prev {
		left: 1.2em;
	}

	.DayPicker-Caption {
		text-align: center;
	}

	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
	.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
		outline: none;
		background: #046daa;
		color: #ffffff;
	}

	.DayPicker-Day--disabled:not(.DayPicker-Day--outside),
	.DayPicker-Day--disabled:not(.DayPicker-Day--outside):hover {
		color: #868686;
		background: #ececec;
		pointer-events: none;
	}

	.DayPicker-Month {
		width: -webkit-fill-available;
		width: -moz-available;
		width: fill-available;
		margin: 1rem 0.5rem;
	}

	.DayPicker-Day {
		border-radius: 0;

		&:not(.DayPicker-Day--outside) {
			border: 1px solid #a2a2a2;
			color: #046daa;
		}
	}
}
